<template>
    <div>
        <editor
            api-key="vx8fq0gg6zt52l1rdvvi20nhj7z5100assg08r19p4ssr9qq"
            
            
            tag-name="div"
            id="mceu_phi" 

            :value="value"      
            
            @onKeyUp="updateContent"   
            @onExecCommand="updateContent"   
            @onSetContent="updateContent"   

            :init="editorConfig"
        />
    </div>
</template>

<script>
import app from '../store/app.js';
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "tiny-mce2",
    components: {
        'editor': Editor
    },
    data() {
        return {
            app,
            instance: null,
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        },

        editorConfig: {
            type: Object,
            default() {
                return {
                    language: this.$store.state.i18n.language,
                    
                    menubar: false,
                    height: 300,
                    width: '98%',
                    
                    resize_img_proportional: true,
                    toolbar_location: 'bottom',
                    init_instance_callback : function(editor) {                        
                        var freeTiny = document.querySelector('.tox .tox-notification--in');
                        freeTiny.style.display = 'none';
                    }                  
                };
            }
        },
    },
    
    methods:{
        updateContent(event, editor){
            this.$emit('input', editor.getContent());
        }
    } 
}
</script>

<style lang="scss">
.phi-post-editor {
    .mce-container {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>